var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eicproduct-container" },
    [
      _vm.loadState === undefined
        ? _c("div", { staticClass: "eicproduct__page-loader" }, [
            _c("img", {
              staticClass: "eicproduct__page-loader--svg",
              attrs: {
                src:
                  "/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Rebates",
              state: "error",
              phonetext: "For further help, please call us at",
              closepath: "/electric-vehicle-rebate"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete" && _vm.isCommercialAccount
        ? _c(
            "div",
            [
              _c("EVRebateNotEligible", {
                attrs: { currentAccount: _vm.currentAccount }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete" && !_vm.isCommercialAccount
        ? _c("div", [
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-1" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("1")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(0)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    !_vm.userIsOTP
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-m"
                                          },
                                          [
                                            _vm._v(
                                              "Account: " +
                                                _vm._s(
                                                  _vm.currentAccount.nickname
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.userIsOTP
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-m"
                                          },
                                          [
                                            _vm._v(
                                              "Account Number: " +
                                                _vm._s(
                                                  _vm.currentAccount
                                                    .accountNumber
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(1)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 1
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 1 && _vm.userIsOTP
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "legend",
                                  {
                                    staticClass:
                                      "gds-body-normal gds-space-stack-ml"
                                  },
                                  [
                                    _vm._v(
                                      "Verify your GMP account information to use for your\n              electric vehicle rebate."
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    key: _vm.currentAccount.accountNumber,
                                    staticClass:
                                      "eicproduct-login__form--radio-label-flex gds-radio gds-space-stack-m"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "eicproduct__account-box gds-space-stack-l"
                                      },
                                      [
                                        _vm.currentAccount
                                          ? _c("span", [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(1),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("titlecase")(
                                                              _vm.currentAccount
                                                                .lastName +
                                                                ", " +
                                                                _vm
                                                                  .currentAccount
                                                                  .firstName
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(2),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "addressFormat"
                                                            )(
                                                              _vm.currentAccount
                                                                .address,
                                                              {
                                                                separator: ", "
                                                              }
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(3),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.currentAccount
                                                              .accountNumber
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "eicproduct__method-next-btn gds-button gds-compact",
                                    on: {
                                      click: function($event) {
                                        return _vm.finishPage("page1", 2)
                                      }
                                    }
                                  },
                                  [_vm._v("Next")]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentPage === 1 && !_vm.userIsOTP
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "legend",
                                  {
                                    staticClass:
                                      "gds-body-normal gds-space-stack-ml"
                                  },
                                  [
                                    _vm._v(
                                      "Verify your GMP account information to use for your\n              electric vehicle rebate."
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct__account-box gds-space-stack-l"
                                  },
                                  [
                                    _vm.currentAccount
                                      ? _c("span", [
                                          _c("div", { staticClass: "row-m" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "account-info-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(4),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("titlecase")(
                                                            _vm.currentAccount
                                                              .lastName +
                                                              ", " +
                                                              _vm.currentAccount
                                                                .firstName
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(5),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "addressFormat"
                                                          )(
                                                            _vm.currentAccount
                                                              .address,
                                                            { separator: ", " }
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(6),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.currentAccount
                                                            .accountNumber
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.accounts.length > 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "inline accountInformation eicproduct__edit-account-details",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.chooseAccount()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "gds-button gds-text-button choose-account-button"
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass:
                                                              "eicproduct__edit-icon-inline gds-icon"
                                                          },
                                                          [
                                                            _c("use", {
                                                              attrs: {
                                                                "xlink:href":
                                                                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit"
                                                              }
                                                            })
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.accounts.length > 1
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "Switch Account"
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "eicproduct__method-next-btn gds-button gds-compact",
                                    on: {
                                      click: function($event) {
                                        return _vm.finishPage("page1", 2)
                                      }
                                    }
                                  },
                                  [_vm._v("Next")]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-2" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("2")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(7)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-normal gds-space-inline-m"
                                      },
                                      [
                                        _vm._v(
                                          "Rebate: " +
                                            _vm._s(
                                              _vm.productSubmission.vehicles[0]
                                                .brandModel
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(2)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 2
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 2
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "rebate-slap__inner-row" },
                                  [
                                    _c("div", [
                                      _c(
                                        "form",
                                        { attrs: { "data-vv-scope": "page2" } },
                                        [
                                          _c(
                                            "fieldset",
                                            { staticClass: "gds-fieldset" },
                                            [
                                              _c(
                                                "h1",
                                                {
                                                  staticClass:
                                                    "gds-display-1 gds-space-stack-ml"
                                                },
                                                [_vm._v("Vehicle Information")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [_vm._v("Purchase Price")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-position--relative"
                                                    },
                                                    [
                                                      _c("money", {
                                                        directives: [
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value:
                                                              "required|min_value:1|max_value:9999999",
                                                            expression:
                                                              "'required|min_value:1|max_value:9999999'"
                                                          }
                                                        ],
                                                        key: "purchase price",
                                                        ref: "focuser2",
                                                        staticClass:
                                                          "eicproduct-input-field__input gds-input-field__input",
                                                        attrs: {
                                                          type: "text",
                                                          name:
                                                            "purchase price",
                                                          id: "purchase price",
                                                          precision: 0,
                                                          placeholder:
                                                            "Type here..."
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .productSubmission
                                                              .vehicles[0]
                                                              .purchasePrice,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .productSubmission
                                                                .vehicles[0],
                                                              "purchasePrice",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "productSubmission.vehicles[0].purchasePrice"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.errors.first(
                                                        "purchase price",
                                                        "page2"
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "validation-error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.errors.first(
                                                                    "purchase price",
                                                                    "page2"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Vehicle VIN (17 Digits)"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-position--relative"
                                                    },
                                                    [
                                                      _c("the-mask", {
                                                        directives: [
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value:
                                                              "required|length:17",
                                                            expression:
                                                              "'required|length:17'"
                                                          }
                                                        ],
                                                        key: "VIN",
                                                        ref: "focuser2",
                                                        staticClass:
                                                          "eicproduct-input-field__input gds-input-field__input",
                                                        attrs: {
                                                          mask:
                                                            "XXXXXXXXXXXXXXXXX",
                                                          type: "text",
                                                          name: "VIN",
                                                          id: "vin",
                                                          placeholder:
                                                            "Type here..."
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .productSubmission
                                                              .vehicles[0]
                                                              .vinNumber,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .productSubmission
                                                                .vehicles[0],
                                                              "vinNumber",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "productSubmission.vehicles[0].vinNumber"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.errors.first(
                                                        "VIN",
                                                        "page2"
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "validation-error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.errors.first(
                                                                    "VIN",
                                                                    "page2"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "gds-input-field__label"
                                                },
                                                [_vm._v("Engine Type")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-l"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-flex-container gds-flex-container--left gds-flex-container--space-between radioContainer"
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-radio"
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0]
                                                                    .engineType,
                                                                expression:
                                                                  "productSubmission.vehicles[0].engineType"
                                                              },
                                                              {
                                                                name:
                                                                  "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required",
                                                                expression:
                                                                  "'required'"
                                                              }
                                                            ],
                                                            key: "engine type",
                                                            attrs: {
                                                              type: "radio",
                                                              name:
                                                                "engine type"
                                                            },
                                                            domProps: {
                                                              value:
                                                                "All Electric",
                                                              checked: _vm._q(
                                                                _vm
                                                                  .productSubmission
                                                                  .vehicles[0]
                                                                  .engineType,
                                                                "All Electric"
                                                              )
                                                            },
                                                            on: {
                                                              change: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    _vm
                                                                      .productSubmission
                                                                      .vehicles[0],
                                                                    "engineType",
                                                                    "All Electric"
                                                                  )
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  return _vm.handleEngineTypeChange()
                                                                }
                                                              ]
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("span", {
                                                            staticClass:
                                                              "gds-radio__faux"
                                                          }),
                                                          _vm._v(" "),
                                                          _vm._m(8)
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-radio"
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0]
                                                                    .engineType,
                                                                expression:
                                                                  "productSubmission.vehicles[0].engineType"
                                                              }
                                                            ],
                                                            key: "engine type",
                                                            attrs: {
                                                              type: "radio",
                                                              name:
                                                                "engine type"
                                                            },
                                                            domProps: {
                                                              value: "Hybrid",
                                                              checked: _vm._q(
                                                                _vm
                                                                  .productSubmission
                                                                  .vehicles[0]
                                                                  .engineType,
                                                                "Hybrid"
                                                              )
                                                            },
                                                            on: {
                                                              change: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    _vm
                                                                      .productSubmission
                                                                      .vehicles[0],
                                                                    "engineType",
                                                                    "Hybrid"
                                                                  )
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  return _vm.handleEngineTypeChange()
                                                                }
                                                              ]
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("span", {
                                                            staticClass:
                                                              "gds-radio__faux"
                                                          }),
                                                          _vm._v(" "),
                                                          _vm._m(9)
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "engine type",
                                                    "page2"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "engine type",
                                                                "page2"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "row-l" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-item margin-right gds-space-stack-l"
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-input-field"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "gds-input-field__label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Vehicle Make and Model"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .vehicles[0]
                                                                      .brandModel,
                                                                  expression:
                                                                    "productSubmission.vehicles[0].brandModel"
                                                                },
                                                                {
                                                                  name:
                                                                    "validate",
                                                                  rawName:
                                                                    "v-validate",
                                                                  value:
                                                                    "required",
                                                                  expression:
                                                                    "'required'"
                                                                }
                                                              ],
                                                              key: "make-model",
                                                              staticClass:
                                                                "eicproduct-input-field-l__select gds-input-field",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disableBrandModel,
                                                                name:
                                                                  "make-model",
                                                                autofocus: ""
                                                              },
                                                              on: {
                                                                change: [
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal = Array.prototype.filter
                                                                      .call(
                                                                        $event
                                                                          .target
                                                                          .options,
                                                                        function(
                                                                          o
                                                                        ) {
                                                                          return o.selected
                                                                        }
                                                                      )
                                                                      .map(
                                                                        function(
                                                                          o
                                                                        ) {
                                                                          var val =
                                                                            "_value" in
                                                                            o
                                                                              ? o._value
                                                                              : o.value
                                                                          return val
                                                                        }
                                                                      )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .vehicles[0],
                                                                      "brandModel",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.parseVehicle(
                                                                      _vm
                                                                        .productSubmission
                                                                        .vehicles[0]
                                                                        .brandModel
                                                                    )
                                                                  }
                                                                ]
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "",
                                                                    disabled: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Select Make/Model"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                _vm.filteredVehicleModels,
                                                                function(
                                                                  model,
                                                                  i
                                                                ) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      key: i,
                                                                      domProps: {
                                                                        value: model
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "formatVehicleModel"
                                                                          )(
                                                                            model
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.errors.first(
                                                            "make-model",
                                                            "page2"
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "validation-error"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.errors.first(
                                                                        "make-model",
                                                                        "page2"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-item gds-space-stack-ml"
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-input-field gds-space-stack-ml"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "gds-input-field__label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Model Year (YYYY)"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("the-mask", {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required|numeric|min_value: 2000|max_value: " +
                                                                  parseInt(
                                                                    new Date().getFullYear() +
                                                                      5
                                                                  ),
                                                                expression:
                                                                  "`required|numeric|min_value: 2000|max_value: ${parseInt(new Date().getFullYear() + 5)}`"
                                                              }
                                                            ],
                                                            key: "year",
                                                            staticClass:
                                                              "gds-input-field__input",
                                                            attrs: {
                                                              mask: "####",
                                                              name: "year",
                                                              type: "tel",
                                                              autocomplete:
                                                                "off",
                                                              placeholder:
                                                                "YYYY"
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                return _vm.$emit(
                                                                  "touched",
                                                                  true
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .productSubmission
                                                                  .vehicles[0]
                                                                  .year,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0],
                                                                  "year",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "productSubmission.vehicles[0].year"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _vm.errors.first(
                                                            "year",
                                                            "page2"
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "eicproduct-validation-error"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.errors.first(
                                                                        "year",
                                                                        "page2"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "gds-input-field__label"
                                                },
                                                [_vm._v("New or Used Vehicle")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-l"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-flex-container gds-flex-container--left gds-flex-container--space-between radioContainer"
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-radio"
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0]
                                                                    .preOwned,
                                                                expression:
                                                                  "productSubmission.vehicles[0].preOwned"
                                                              },
                                                              {
                                                                name:
                                                                  "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required",
                                                                expression:
                                                                  "'required'"
                                                              }
                                                            ],
                                                            attrs: {
                                                              type: "radio",
                                                              name:
                                                                "new or used"
                                                            },
                                                            domProps: {
                                                              value: false,
                                                              checked: _vm._q(
                                                                _vm
                                                                  .productSubmission
                                                                  .vehicles[0]
                                                                  .preOwned,
                                                                false
                                                              )
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0],
                                                                  "preOwned",
                                                                  false
                                                                )
                                                              }
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("span", {
                                                            staticClass:
                                                              "gds-radio__faux"
                                                          }),
                                                          _vm._v(" "),
                                                          _vm._m(10)
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-radio"
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0]
                                                                    .preOwned,
                                                                expression:
                                                                  "productSubmission.vehicles[0].preOwned"
                                                              }
                                                            ],
                                                            attrs: {
                                                              type: "radio",
                                                              name:
                                                                "new or used"
                                                            },
                                                            domProps: {
                                                              value: true,
                                                              checked: _vm._q(
                                                                _vm
                                                                  .productSubmission
                                                                  .vehicles[0]
                                                                  .preOwned,
                                                                true
                                                              )
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0],
                                                                  "preOwned",
                                                                  true
                                                                )
                                                              }
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("span", {
                                                            staticClass:
                                                              "gds-radio__faux"
                                                          }),
                                                          _vm._v(" "),
                                                          _vm._m(11)
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "new or used",
                                                    "page2"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "eicproduct-validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "new or used",
                                                                "page2"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-l"
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "gds-input-field gds-space-stack-ml",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "gds-input-field__label"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Purchase Date"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "eicproduct-purchasedate__date-box gds-space-stack-ml"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "my-account-billing-report__filtration-date-icon",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$refs.datePicker.showCalendar()
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  staticClass:
                                                                    "gds-icon"
                                                                },
                                                                [
                                                                  _c("use", {
                                                                    attrs: {
                                                                      "xlink:href":
                                                                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar"
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("datepicker", {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required",
                                                                expression:
                                                                  "'required'"
                                                              }
                                                            ],
                                                            key:
                                                              "date of purchase",
                                                            ref: "datePicker",
                                                            attrs: {
                                                              name:
                                                                "date of purchase",
                                                              format:
                                                                "MMMM d, yyyy",
                                                              typeable: true,
                                                              placeholder:
                                                                "Select Date...",
                                                              "disabled-dates":
                                                                _vm.disableFutureDates,
                                                              "input-class":
                                                                "export-data__date my-account-billing-report__filtration-date-text"
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.onChangeDateOfPurchase
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .productSubmission
                                                                  .vehicles[0]
                                                                  .dateOfPurchase,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0],
                                                                  "dateOfPurchase",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "productSubmission.vehicles[0].dateOfPurchase"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.errors.first(
                                                        "date of purchase",
                                                        "page2"
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "eicproduct-validation-error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.errors.first(
                                                                    "date of purchase",
                                                                    "page2"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-space-stack-l",
                                                      attrs: {
                                                        id: "dealership"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-input-field"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "gds-input-field__label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                'Dealership (If private sale, enter "Private\n                            Sale")'
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0]
                                                                    .dealerName,
                                                                expression:
                                                                  "productSubmission.vehicles[0].dealerName"
                                                              },
                                                              {
                                                                name:
                                                                  "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required",
                                                                expression:
                                                                  "'required'"
                                                              }
                                                            ],
                                                            key: "dealership",
                                                            staticClass:
                                                              "gds-input-field__input gds-space-stack-s eicproduct-input-field__input",
                                                            attrs: {
                                                              name:
                                                                "dealership",
                                                              type: "text",
                                                              autocomplete:
                                                                "off",
                                                              placeholder:
                                                                "Type Here..."
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm
                                                                  .productSubmission
                                                                  .vehicles[0]
                                                                  .dealerName
                                                            },
                                                            on: {
                                                              keyup: function(
                                                                $event
                                                              ) {
                                                                return _vm.selectDealership(
                                                                  $event
                                                                )
                                                              },
                                                              input: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  ) {
                                                                    return
                                                                  }
                                                                  _vm.$set(
                                                                    _vm
                                                                      .productSubmission
                                                                      .vehicles[0],
                                                                    "dealerName",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  _vm.$emit(
                                                                    "touched",
                                                                    true
                                                                  )
                                                                  _vm.filterDealerships()
                                                                }
                                                              ],
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.filterDealerships()
                                                              }
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _vm.errors.first(
                                                            "dealership",
                                                            "page2"
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "eicproduct-validation-error"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.errors.first(
                                                                        "dealership",
                                                                        "page2"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.filteredDealerships
                                                        .length > 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "typeAhead-container"
                                                            },
                                                            _vm._l(
                                                              _vm.filteredDealerships,
                                                              function(dealer) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key:
                                                                      dealer.id,
                                                                    staticClass:
                                                                      "typeAhead-card gds-space-stack-m",
                                                                    class: {
                                                                      "active-dealership typeAhead-card gds-space-stack-m":
                                                                        _vm.currentDealership ===
                                                                        dealer.id
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.setDealership(
                                                                          dealer.name
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "typeAhead-text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            dealer.name
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "gds-button gds-compact",
                                        on: {
                                          click: function($event) {
                                            return _vm.finishPage("page2", 3)
                                          }
                                        }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-3" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        ref: "focuser3",
                        class:
                          _vm.currentPage === 3
                            ? "eicproduct-slat__header gds-slat__header gds-space-stack-ml"
                            : "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("3")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(12)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-normal gds-space-inline-m"
                                      },
                                      [
                                        _vm._v(
                                          "Total Savings: $" +
                                            _vm._s(_vm.totalSavings)
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(3)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 3
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 3
                      ? _c(
                          "div",
                          {
                            class:
                              this.productSubmission.vehicles[0].preOwned ===
                              false
                                ? "eicproduct-slat-3__contents"
                                : undefined,
                            attrs: { id: "eicproduct-slat-3__contents" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner",
                                attrs: {
                                  id: "eicproduct-slat-3__contents-inner"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-s" },
                                  [
                                    _c(
                                      "form",
                                      { attrs: { "data-vv-scope": "page3" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class:
                                              "savings-offer-row gds-space-stack-l"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "gds-space-stack-l"
                                              },
                                              [
                                                _vm.productSubmission
                                                  .vehicles[0].engineType ===
                                                "Hybrid"
                                                  ? _c("div", [
                                                      _c(
                                                        "h1",
                                                        {
                                                          staticClass:
                                                            "gds-display-1 gds-space-stack-m"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Income Qualified Incentive"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gds-body-small slat3-body gds-space-stack-l"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Low & Moderate Income Rebate is\n                        available only for all-electric vehicles, new or used, and only for customers who meet income\n                        requirements. Based on the information you've provided, the Income Qualified Incentive is not\n                        available."
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.productSubmission
                                                  .vehicles[0].engineType ===
                                                "All Electric"
                                                  ? _c("div", [
                                                      _c(
                                                        "h1",
                                                        {
                                                          staticClass:
                                                            "gds-display-1 gds-space-stack-m"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Provide info for income-based incentive eligibility.\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      !_vm.isRestrictedRebateUser
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "gds-body-small slat3-body gds-space-stack-ml"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Additional incentives are available\n                        based on household income while funds last. Please provide your gross household income info to\n                        see if you qualify. Rebate total will update if you are eligible."
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.isRestrictedRebateUser &&
                                                      _vm.productSubmission
                                                        .vehicles[0]
                                                        .engineType ===
                                                        "All Electric"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row-s"
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "gds-input-field"
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "gds-input-field__label"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Did the customer attest that they are eligibile for the income based incentive?"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "gds-space-stack-ml"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "select",
                                                                        {
                                                                          directives: [
                                                                            {
                                                                              name:
                                                                                "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm
                                                                                  .productSubmission
                                                                                  .isEligibleForIncomeIncentive,
                                                                              expression:
                                                                                "productSubmission.isEligibleForIncomeIncentive"
                                                                            },
                                                                            {
                                                                              name:
                                                                                "validate",
                                                                              rawName:
                                                                                "v-validate",
                                                                              value:
                                                                                "required",
                                                                              expression:
                                                                                "'required'"
                                                                            }
                                                                          ],
                                                                          key:
                                                                            "Income Based Incentive",
                                                                          staticClass:
                                                                            "eicproduct-input-field__select",
                                                                          attrs: {
                                                                            name:
                                                                              "Income Based Incentive",
                                                                            autofocus:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            change: [
                                                                              function(
                                                                                $event
                                                                              ) {
                                                                                var $$selectedVal = Array.prototype.filter
                                                                                  .call(
                                                                                    $event
                                                                                      .target
                                                                                      .options,
                                                                                    function(
                                                                                      o
                                                                                    ) {
                                                                                      return o.selected
                                                                                    }
                                                                                  )
                                                                                  .map(
                                                                                    function(
                                                                                      o
                                                                                    ) {
                                                                                      var val =
                                                                                        "_value" in
                                                                                        o
                                                                                          ? o._value
                                                                                          : o.value
                                                                                      return val
                                                                                    }
                                                                                  )
                                                                                _vm.$set(
                                                                                  _vm.productSubmission,
                                                                                  "isEligibleForIncomeIncentive",
                                                                                  $event
                                                                                    .target
                                                                                    .multiple
                                                                                    ? $$selectedVal
                                                                                    : $$selectedVal[0]
                                                                                )
                                                                              },
                                                                              function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.onChangeIncomeIncentiveEligibility(
                                                                                  $event
                                                                                )
                                                                              }
                                                                            ]
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "option",
                                                                            {
                                                                              attrs: {
                                                                                value:
                                                                                  "",
                                                                                disabled:
                                                                                  "",
                                                                                select:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Select..."
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "option",
                                                                            {
                                                                              attrs: {
                                                                                value:
                                                                                  "yes"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Yes"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "option",
                                                                            {
                                                                              attrs: {
                                                                                value:
                                                                                  "no"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "No"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm.errors.first(
                                                                    "Income Based Incentive",
                                                                    "page3"
                                                                  )
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "validation-error"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.errors.first(
                                                                                "Income Based Incentive",
                                                                                "page3"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      !_vm.isRestrictedRebateUser
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row-s"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "gds-space-stack-m"
                                                                },
                                                                [
                                                                  _c(
                                                                    "label",
                                                                    {
                                                                      staticClass:
                                                                        "gds-input-field"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "gds-input-field__label"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Number Of Household Members"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "gds-space-stack-ml"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "select",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .productSubmission
                                                                                      .rebate
                                                                                      .householdIncome
                                                                                      .numberInHousehold,
                                                                                  expression:
                                                                                    "productSubmission.rebate.householdIncome.numberInHousehold"
                                                                                },
                                                                                {
                                                                                  name:
                                                                                    "validate",
                                                                                  rawName:
                                                                                    "v-validate",
                                                                                  value:
                                                                                    "required",
                                                                                  expression:
                                                                                    "'required'"
                                                                                }
                                                                              ],
                                                                              key:
                                                                                "number in household",
                                                                              staticClass:
                                                                                "eicproduct-input-field__select",
                                                                              attrs: {
                                                                                name:
                                                                                  "number in household",
                                                                                autofocus:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                change: [
                                                                                  function(
                                                                                    $event
                                                                                  ) {
                                                                                    var $$selectedVal = Array.prototype.filter
                                                                                      .call(
                                                                                        $event
                                                                                          .target
                                                                                          .options,
                                                                                        function(
                                                                                          o
                                                                                        ) {
                                                                                          return o.selected
                                                                                        }
                                                                                      )
                                                                                      .map(
                                                                                        function(
                                                                                          o
                                                                                        ) {
                                                                                          var val =
                                                                                            "_value" in
                                                                                            o
                                                                                              ? o._value
                                                                                              : o.value
                                                                                          return val
                                                                                        }
                                                                                      )
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .productSubmission
                                                                                        .rebate
                                                                                        .householdIncome,
                                                                                      "numberInHousehold",
                                                                                      $event
                                                                                        .target
                                                                                        .multiple
                                                                                        ? $$selectedVal
                                                                                        : $$selectedVal[0]
                                                                                    )
                                                                                  },
                                                                                  function(
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.addToTotalSavings()
                                                                                    _vm.buildIncomeDropDown()
                                                                                  }
                                                                                ]
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._l(
                                                                                _vm
                                                                                  .metadata
                                                                                  .incomeIncentives,
                                                                                function(
                                                                                  option,
                                                                                  index
                                                                                ) {
                                                                                  return _c(
                                                                                    "option",
                                                                                    {
                                                                                      key: index,
                                                                                      domProps: {
                                                                                        value:
                                                                                          option.numberInHousehold
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          option.numberInHousehold
                                                                                        ) +
                                                                                          "\n                                "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "option",
                                                                                {
                                                                                  attrs: {
                                                                                    value:
                                                                                      "",
                                                                                    disabled:
                                                                                      "",
                                                                                    select:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Select..."
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            2
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm.errors.first(
                                                                    "number in household",
                                                                    "page3"
                                                                  )
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "eicproduct-validation-error",
                                                                          staticStyle: {
                                                                            "max-width":
                                                                              "80%"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.errors.first(
                                                                                "number in household",
                                                                                "page3"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "gds-input-field"
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "gds-input-field__label"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Gross Household Income in " +
                                                                          _vm._s(
                                                                            _vm.lastYear
                                                                          )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "select",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .productSubmission
                                                                              .rebate
                                                                              .householdIncome
                                                                              .grossHouseholdIncome,
                                                                          expression:
                                                                            "productSubmission.rebate.householdIncome.grossHouseholdIncome"
                                                                        },
                                                                        {
                                                                          name:
                                                                            "validate",
                                                                          rawName:
                                                                            "v-validate",
                                                                          value:
                                                                            "required",
                                                                          expression:
                                                                            "'required'"
                                                                        }
                                                                      ],
                                                                      key:
                                                                        "gross household income",
                                                                      staticClass:
                                                                        "eicproduct-input-field__select gds-space-stack-ml",
                                                                      attrs: {
                                                                        autofocus:
                                                                          "",
                                                                        name:
                                                                          "gross household income",
                                                                        disabled: !_vm
                                                                          .productSubmission
                                                                          .rebate
                                                                          .householdIncome
                                                                          .numberInHousehold
                                                                          ? true
                                                                          : false
                                                                      },
                                                                      on: {
                                                                        change: [
                                                                          function(
                                                                            $event
                                                                          ) {
                                                                            var $$selectedVal = Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function(
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function(
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                            _vm.$set(
                                                                              _vm
                                                                                .productSubmission
                                                                                .rebate
                                                                                .householdIncome,
                                                                              "grossHouseholdIncome",
                                                                              $event
                                                                                .target
                                                                                .multiple
                                                                                ? $$selectedVal
                                                                                : $$selectedVal[0]
                                                                            )
                                                                          },
                                                                          function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.addToTotalSavings()
                                                                          }
                                                                        ]
                                                                      }
                                                                    },
                                                                    _vm._l(
                                                                      _vm.incomeBasedEVIncentiveDropdown,
                                                                      function(
                                                                        option,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "option",
                                                                          {
                                                                            key: index,
                                                                            domProps: {
                                                                              value:
                                                                                option.incomeAmount
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                option.incomeOption
                                                                              ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm.errors.first(
                                                                    "gross household income",
                                                                    "page3"
                                                                  )
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "eicproduct-validation-error"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                _vm.errors.first(
                                                                                  "gross household income",
                                                                                  "page3"
                                                                                )
                                                                              )
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "hideIfDesktop" },
                                      [
                                        _vm.currentPage === 3 &&
                                        _vm.totalSavings > 0
                                          ? _c("rebate-calculator", {
                                              attrs: {
                                                engineType:
                                                  _vm.productSubmission
                                                    .vehicles[0].engineType,
                                                preOwned:
                                                  _vm.productSubmission
                                                    .vehicles[0].preOwned,
                                                totalSavings: _vm.totalSavings,
                                                usedRebateAmount:
                                                  _vm.usedEVRebateAmount,
                                                allElectricNewRebateAmount:
                                                  _vm.newEVRebateAmount,
                                                hybridNewRebateAmount:
                                                  _vm.newHybridRebateAmount,
                                                hybridUsedRebateAmount:
                                                  _vm.usedHybridRebateAmount,
                                                incomeQualifiedRebate:
                                                  _vm.incomeQualifiedRebate
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct__method-next-btn gds-button gds-compact",
                                        on: {
                                          click: function($event) {
                                            return _vm.finishPage("page3", 4)
                                          }
                                        }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "hideIfMobile" },
                              [
                                _vm.currentPage === 3 && _vm.totalSavings > 0
                                  ? _c("rebate-calculator", {
                                      attrs: {
                                        engineType:
                                          _vm.productSubmission.vehicles[0]
                                            .engineType,
                                        preOwned:
                                          _vm.productSubmission.vehicles[0]
                                            .preOwned,
                                        totalSavings: _vm.totalSavings,
                                        usedRebateAmount:
                                          _vm.usedEVRebateAmount,
                                        allElectricNewRebateAmount:
                                          _vm.newEVRebateAmount,
                                        hybridNewRebateAmount:
                                          _vm.newHybridRebateAmount,
                                        hybridUsedRebateAmount:
                                          _vm.usedHybridRebateAmount,
                                        incomeQualifiedRebate:
                                          _vm.incomeQualifiedRebate
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-4" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 4
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("4")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 4
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "gds-slat__title-wrapper" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__title gds-slat__title"
                                  },
                                  [_vm._v("Upload Documents")]
                                ),
                                _vm._v(" "),
                                _vm.selectedFile
                                  ? _c("modal-document-checklist", {
                                      attrs: {
                                        visible: _vm.showDocumentChecklist,
                                        isRestrictedRebateUser:
                                          _vm.isRestrictedRebateUser,
                                        select: _vm.onSelect,
                                        save: _vm.onSave,
                                        selectedFile: _vm.selectedFile,
                                        close: _vm.closeModal,
                                        documentTypes: _vm.documentTypes
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showInformationalModal &&
                                !_vm.isRestrictedRebateUser
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "informational",
                                        close: _vm.closeModal,
                                        modalMessage:
                                          "On your W9 please make sure you have Selected a box in item 3 and have signed it. Please make sure to have the following info on your proof of purchase:",
                                        listItems: [
                                          "Make and Model of EV",
                                          "Date of Purchase",
                                          "VIN"
                                        ]
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showInformationalModal &&
                                _vm.isRestrictedRebateUser
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "informational",
                                        close: _vm.closeModal,
                                        modalMessage:
                                          "Please make sure to have the following info on your proof of purchase:",
                                        listItems: [
                                          "Make and Model of EV",
                                          "Date of Purchase",
                                          "VIN"
                                        ]
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showProofOfPurchaseInfoModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "proofOfPurchaseInfo",
                                        close: _vm.closeModal,
                                        modalMessage:
                                          "Please make sure you have the following information with your vehicle proof of purchase:",
                                        listItems: [
                                          "Your Name",
                                          "Date of purchase",
                                          "VIN",
                                          "Your address"
                                        ]
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showW9InfoModal &&
                                !_vm.isRestrictedRebateUser
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "w9Info",
                                        close: _vm.closeModal,
                                        modalMessage:
                                          "Please make sure you selected a box in item 3 and that you've signed your W9."
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showMissingDocumentsModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "missingDocument",
                                        close: _vm.onMissingDocumentsModalClose,
                                        modalTitle:
                                          "You’re missing a required document",
                                        modalMessage:
                                          "Please upload a copy of the following:",
                                        listItems: _vm.missingDocuments
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showFileLoadTooBigModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "fileLoadTooBig",
                                        close: _vm.closeModal,
                                        modalMessage:
                                          "Your total file size is " +
                                          this.$options.filters.fileSizeFormat(
                                            _vm.totalFileSize
                                          ) +
                                          ". Please make sure the combined size of all uploaded files is less than " +
                                          this.$options.filters.fileSizeFormat(
                                            _vm.maxFileLoadSize
                                          ) +
                                          "."
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showDuplicateFilenameModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "duplicateFilename",
                                        close: _vm.closeModal,
                                        modalMessage:
                                          "Each file that is uploaded must have a unique file name."
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 4
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _vm.documents.length > 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-s"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                this.documents.length +
                                                  "\n              Files Uploaded"
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.documents.length === 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-s"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  this.documents.length +
                                                    " File Uploaded"
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(4)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 4
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 4
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-l" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "slat-title-wrapper" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "eicproduct-slat__contents-inner"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "gds-space-stack-l"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "slat-title-wrapper"
                                                  },
                                                  [
                                                    _c("form", [
                                                      _c(
                                                        "div",
                                                        {
                                                          attrs: {
                                                            id: "fileUploader"
                                                          }
                                                        },
                                                        [
                                                          _c("File-Uploader", {
                                                            ref: "fileUploader",
                                                            attrs: {
                                                              uploadDocumentList:
                                                                _vm.uploadDocumentList,
                                                              docTypes:
                                                                _vm.documentTypes,
                                                              allowCombined:
                                                                _vm.isRestrictedRebateUser
                                                            },
                                                            on: {
                                                              "file-list-changed":
                                                                _vm.updateFileList
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "eicproduct__method-next-btn gds-button gds-compact",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.finishPage(
                                                          "page4",
                                                          5
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Next")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-5" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("5")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(13)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "gds-body-normal gds-space-inline-m"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(5)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 5
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 5
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "form",
                                  { attrs: { "data-vv-scope": "page5" } },
                                  [
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass:
                                          "gds-fieldset gds-space-stack-s"
                                      },
                                      [
                                        !_vm.isLevel2Eligible
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "gds-body-small gds-space-stack-l"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-body-normal gds-space-stack-l slat3-body"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "While GMP does not provide the EV home charger for Plug-In Hybrid Vehicles,\n                    should you want to enroll in one of GMP’s reduced EV charging rates you will need to purchase and install a compatible \n                    level 2 home charger.  You will find those compatible chargers on our website here: "
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            _vm.COMPATIBLE_CHARGER_LINK
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.COMPATIBLE_CHARGER_LINK_NAME
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isLevel2Eligible
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "gds-body-small gds-space-stack-l"
                                              },
                                              [
                                                !_vm.isRestrictedRebateUser
                                                  ? _c(
                                                      "h1",
                                                      {
                                                        staticClass:
                                                          "gds-display-1 gds-space-stack-s"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Get a free charger when you sign up for the home\n                    charging program!"
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.isRestrictedRebateUser
                                                  ? _c(
                                                      "h1",
                                                      {
                                                        staticClass:
                                                          "gds-display-1 gds-space-stack-s"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Enroll a customer to receive a free charger by signing them up for the home\n                    charging program!"
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isLevel2Eligible
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "gds-flex-container level2Charger gds-space-stack-l"
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    id: "level2Charger",
                                                    src:
                                                      "/wp-content/themes/gmptwentynineteen/assets/images/GMP-EV-thanks.jpeg",
                                                    alt: "Level 2 Charger"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "75%"
                                                    }
                                                  },
                                                  [
                                                    !_vm.isRestrictedRebateUser
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "gds-body-small gds-space-stack-l slat3-body"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "With the purchase of a new all electric vehicle,\n                      GMP will provide you with a Level 2 Charger to have installed at your home. These chargers allow you to \n                      get our discount EV charging rates to save on your charging costs."
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.isRestrictedRebateUser
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "gds-body-small gds-space-stack-l slat3-body"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "With the purchase of a new all electric vehicle,\n                      GMP will provide a customer with a Level 2 Charger to have installed at their home. These chargers allow a customer to \n                      enroll in our discount EV charging rates to save on charging costs."
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "gds-space-stack-l agreement"
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "gds-checkbox gds-checkbox-thin",
                                                            attrs: {
                                                              id:
                                                                "chargerCheckbox"
                                                            }
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .programEnrollment
                                                                      .inHomeChargerProgram
                                                                      .enrollInHomeChargerProgram,
                                                                  expression:
                                                                    "productSubmission.programEnrollment.inHomeChargerProgram.enrollInHomeChargerProgram"
                                                                }
                                                              ],
                                                              attrs: {
                                                                type: "checkbox"
                                                              },
                                                              domProps: {
                                                                checked: Array.isArray(
                                                                  _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .inHomeChargerProgram
                                                                    .enrollInHomeChargerProgram
                                                                )
                                                                  ? _vm._i(
                                                                      _vm
                                                                        .productSubmission
                                                                        .programEnrollment
                                                                        .inHomeChargerProgram
                                                                        .enrollInHomeChargerProgram,
                                                                      null
                                                                    ) > -1
                                                                  : _vm
                                                                      .productSubmission
                                                                      .programEnrollment
                                                                      .inHomeChargerProgram
                                                                      .enrollInHomeChargerProgram
                                                              },
                                                              on: {
                                                                change: [
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        _vm
                                                                          .productSubmission
                                                                          .programEnrollment
                                                                          .inHomeChargerProgram
                                                                          .enrollInHomeChargerProgram,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c = $$el.checked
                                                                        ? true
                                                                        : false
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v = null,
                                                                        $$i = _vm._i(
                                                                          $$a,
                                                                          $$v
                                                                        )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          _vm.$set(
                                                                            _vm
                                                                              .productSubmission
                                                                              .programEnrollment
                                                                              .inHomeChargerProgram,
                                                                            "enrollInHomeChargerProgram",
                                                                            $$a.concat(
                                                                              [
                                                                                $$v
                                                                              ]
                                                                            )
                                                                          )
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          _vm.$set(
                                                                            _vm
                                                                              .productSubmission
                                                                              .programEnrollment
                                                                              .inHomeChargerProgram,
                                                                            "enrollInHomeChargerProgram",
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              )
                                                                          )
                                                                      }
                                                                    } else {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .productSubmission
                                                                          .programEnrollment
                                                                          .inHomeChargerProgram,
                                                                        "enrollInHomeChargerProgram",
                                                                        $$c
                                                                      )
                                                                    }
                                                                  },
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.onChangeChargerProgram(
                                                                      $event
                                                                    )
                                                                  }
                                                                ]
                                                              }
                                                            }),
                                                            _vm._v(" "),
                                                            _c("span", {
                                                              staticClass:
                                                                "gds-checkbox__faux"
                                                            }),
                                                            _vm._v(" "),
                                                            !_vm.isRestrictedRebateUser
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    attrs: {
                                                                      id:
                                                                        "level2Agreement"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        "Yes"
                                                                      )
                                                                    ]),
                                                                    _vm._v(
                                                                      " - I agree, I want to join your charging program and receive the charger. Please\n                          send me the agreement.\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.isRestrictedRebateUser
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    attrs: {
                                                                      id:
                                                                        "level2Agreement"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        "Yes"
                                                                      )
                                                                    ]),
                                                                    _vm._v(
                                                                      " - The customer wants to join the charging program and receive a home charger. Please\n                          send the customer the agreement.\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.productSubmission.programEnrollment
                                          .inHomeChargerProgram
                                          .enrollInHomeChargerProgram &&
                                        !_vm.isRestrictedRebateUser
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "eicproduct-slap__inner-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row-s" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "gds-space-stack-m"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "eicproducts-required-text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        In order to identify how to best connect your EV charger to the internet, we have a few\n                        questions.\n                      "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "gds-input-field"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "gds-input-field__label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Do you have an internet connection to your home?"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "gds-space-stack-ml"
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "model",
                                                                        rawName:
                                                                          "v-model",
                                                                        value:
                                                                          _vm.hasInternet,
                                                                        expression:
                                                                          "hasInternet"
                                                                      },
                                                                      {
                                                                        name:
                                                                          "validate",
                                                                        rawName:
                                                                          "v-validate",
                                                                        value: _vm
                                                                          .productSubmission
                                                                          .programEnrollment
                                                                          .inHomeChargerProgram
                                                                          .enrollInHomeChargerProgram
                                                                          ? "required"
                                                                          : null,
                                                                        expression:
                                                                          "productSubmission.programEnrollment.inHomeChargerProgram.enrollInHomeChargerProgram ? 'required' : null"
                                                                      }
                                                                    ],
                                                                    key:
                                                                      "hasInternet",
                                                                    staticClass:
                                                                      "eicproduct-input-field__select",
                                                                    attrs: {
                                                                      name:
                                                                        "hasInternet",
                                                                      autofocus:
                                                                        ""
                                                                    },
                                                                    on: {
                                                                      change: [
                                                                        function(
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal = Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                          _vm.hasInternet = $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        },
                                                                        function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.onChangeInternet(
                                                                            $event
                                                                          )
                                                                        }
                                                                      ]
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "",
                                                                          disabled:
                                                                            "",
                                                                          select:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Select..."
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "yes"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Yes"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "no"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "No"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.errors.first(
                                                              "hasInternet",
                                                              "page5"
                                                            )
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "validation-error"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.errors.first(
                                                                          "hasInternet",
                                                                          "page5"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.productSubmission.programEnrollment
                                          .inHomeChargerProgram
                                          .enrollInHomeChargerProgram &&
                                        _vm.hasInternet === "yes"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "eicproduct-slap__inner-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row-s" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "gds-space-stack-m"
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "gds-input-field"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "gds-input-field__label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Do you have a WiFi (wireless) network in your\n                          home?"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "gds-space-stack-ml"
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "model",
                                                                        rawName:
                                                                          "v-model",
                                                                        value:
                                                                          _vm.hasWifiNetwork,
                                                                        expression:
                                                                          "hasWifiNetwork"
                                                                      },
                                                                      {
                                                                        name:
                                                                          "validate",
                                                                        rawName:
                                                                          "v-validate",
                                                                        value: _vm.hasInternet
                                                                          ? "required"
                                                                          : null,
                                                                        expression:
                                                                          "hasInternet ? 'required' : null"
                                                                      }
                                                                    ],
                                                                    key:
                                                                      "wifiNetworkRequired",
                                                                    staticClass:
                                                                      "eicproduct-input-field__select",
                                                                    attrs: {
                                                                      name:
                                                                        "wifiNetworkRequired",
                                                                      autofocus:
                                                                        ""
                                                                    },
                                                                    on: {
                                                                      change: [
                                                                        function(
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal = Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                          _vm.hasWifiNetwork = $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        },
                                                                        function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.onChangeWifiNetwork(
                                                                            $event
                                                                          )
                                                                        }
                                                                      ]
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "",
                                                                          disabled:
                                                                            "",
                                                                          select:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Select..."
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "yes"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Yes"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "no"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "No"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.errors.first(
                                                              "wifiNetworkRequired",
                                                              "page5"
                                                            )
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "validation-error"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.errors.first(
                                                                          "wifiNetworkRequired",
                                                                          "page5"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.productSubmission.programEnrollment
                                          .inHomeChargerProgram
                                          .enrollInHomeChargerProgram &&
                                        _vm.hasWifiNetwork === "yes"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "eicproduct-slap__inner-row"
                                              },
                                              [
                                                _vm.hasWifiNetwork === "yes"
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "row-s" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "gds-space-stack-m"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "eicproducts-required-text"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        The WiFi signal must strong enough to reach the location where your charger will be\n                        installed/located.\n                      "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "gds-input-field"
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "gds-input-field__label"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Is there a strong, reliable WiFi signal where the EV\n                          charger will be located?"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "gds-position--relative"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "gds-space-stack-ml"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "select",
                                                                          {
                                                                            directives: [
                                                                              {
                                                                                name:
                                                                                  "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm.hasWifiSignalAtCharger,
                                                                                expression:
                                                                                  "hasWifiSignalAtCharger"
                                                                              },
                                                                              {
                                                                                name:
                                                                                  "validate",
                                                                                rawName:
                                                                                  "v-validate",
                                                                                value: _vm.hasWifiNetwork
                                                                                  ? "required"
                                                                                  : null,
                                                                                expression:
                                                                                  "hasWifiNetwork ? 'required' : null"
                                                                              }
                                                                            ],
                                                                            key:
                                                                              "wifiSignalRequired",
                                                                            staticClass:
                                                                              "eicproduct-input-field__select",
                                                                            attrs: {
                                                                              name:
                                                                                "wifiSignalRequired",
                                                                              autofocus:
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              change: [
                                                                                function(
                                                                                  $event
                                                                                ) {
                                                                                  var $$selectedVal = Array.prototype.filter
                                                                                    .call(
                                                                                      $event
                                                                                        .target
                                                                                        .options,
                                                                                      function(
                                                                                        o
                                                                                      ) {
                                                                                        return o.selected
                                                                                      }
                                                                                    )
                                                                                    .map(
                                                                                      function(
                                                                                        o
                                                                                      ) {
                                                                                        var val =
                                                                                          "_value" in
                                                                                          o
                                                                                            ? o._value
                                                                                            : o.value
                                                                                        return val
                                                                                      }
                                                                                    )
                                                                                  _vm.hasWifiSignalAtCharger = $event
                                                                                    .target
                                                                                    .multiple
                                                                                    ? $$selectedVal
                                                                                    : $$selectedVal[0]
                                                                                },
                                                                                function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onChangeWifiSignal(
                                                                                    $event
                                                                                  )
                                                                                }
                                                                              ]
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "option",
                                                                              {
                                                                                attrs: {
                                                                                  value:
                                                                                    "",
                                                                                  disabled:
                                                                                    "",
                                                                                  select:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Select..."
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "option",
                                                                              {
                                                                                attrs: {
                                                                                  value:
                                                                                    "yes"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Yes"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "option",
                                                                              {
                                                                                attrs: {
                                                                                  value:
                                                                                    "no"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "No"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.errors.first(
                                                              "wifiSignalRequired",
                                                              "page5"
                                                            )
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "validation-error"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.errors.first(
                                                                          "wifiSignalRequired",
                                                                          "page5"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.showNoQualifyChargerModal
                              ? _c(
                                  "div",
                                  [
                                    _c("ModalNoQualifyCharger", {
                                      attrs: {
                                        modalType: "informational",
                                        modalMessage: _vm.modalMessage,
                                        close: _vm.closeModal
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "gds-button gds-compact",
                                on: {
                                  click: function($event) {
                                    return _vm.finishPage("page5", 6)
                                  }
                                }
                              },
                              [_vm._v("Next")]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-6" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 6
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("6")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 6
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(14)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 6
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-normal gds-space-inline-s"
                                      },
                                      [
                                        _vm._v(
                                          "Mailing Address: " +
                                            _vm._s(
                                              _vm._f("addressFormat")(
                                                _vm.productSubmission.rebate
                                                  .rebateMailingAddress,
                                                { seperator: ", " }
                                              )
                                            )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(6)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 6
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 6
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-l" },
                                  [
                                    _c(
                                      "form",
                                      { attrs: { "data-vv-scope": "page6" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "gds-space-stack-l" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "gds-input-field"
                                              },
                                              [
                                                !_vm.isRestrictedRebateUser
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "gds-input-field__label"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Please make my check out to:"
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.isRestrictedRebateUser
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "gds-input-field__label"
                                                      },
                                                      [_vm._v("Rebate is for:")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .rebatePayeeName,
                                                      expression:
                                                        "productSubmission.rebate.rebatePayeeName"
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'"
                                                    }
                                                  ],
                                                  key: "rebate payee name",
                                                  ref: "focuser6",
                                                  staticClass:
                                                    "eicproduct-input-field gds-input-field__input",
                                                  attrs: {
                                                    name: "rebate payee name",
                                                    disabled:
                                                      _vm.isRestrictedRebateUser,
                                                    type: "text"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.productSubmission
                                                        .rebate.rebatePayeeName
                                                  },
                                                  on: {
                                                    input: [
                                                      function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.productSubmission
                                                            .rebate,
                                                          "rebatePayeeName",
                                                          $event.target.value
                                                        )
                                                      },
                                                      function($event) {
                                                        return _vm.$emit(
                                                          "touched",
                                                          true
                                                        )
                                                      }
                                                    ]
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _vm.errors.first(
                                                  "rebate payee name",
                                                  "page6"
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproduct-validation-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.errors.first(
                                                              "rebate payee name",
                                                              "page6"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "slat-title-wrapper" },
                                          [
                                            !_vm.isRestrictedRebateUser
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "eicproduct-title"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Where should we send your rebate check?"
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "gds-input-field gds-space-stack-ml"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gds-input-field__label"
                                              },
                                              [_vm._v("Country")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress
                                                        .country,
                                                    expression:
                                                      "productSubmission.rebate.rebateMailingAddress.country"
                                                  }
                                                ],
                                                class:
                                                  _vm.productSubmission.rebate
                                                    .rebateMailingAddress
                                                    .country === "USA"
                                                    ? "eicproduct-input-field__select-country-us gds-input-field gds-space-stack-ml"
                                                    : _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress
                                                        .country === "CAN"
                                                    ? "eicproduct-input-field__select-country-ca gds-input-field"
                                                    : "eicproduct-input-field__select-s gds-input-field",
                                                attrs: {
                                                  disabled:
                                                    _vm.isRestrictedRebateUser,
                                                  autofocus: ""
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress,
                                                      "country",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      selected: "",
                                                      "data-thumbnail":
                                                        "/wp-content/themes/gmptwentynineteen/assets/images/flag_us.svg"
                                                    },
                                                    domProps: { value: "USA" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "United States\n                    "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "CAN" } },
                                                  [_vm._v("Canada")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "FOR" } },
                                                  [_vm._v("Foreign")]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            !_vm.productSubmission.rebate
                                              .rebateMailingAddress.country
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "eicproduct-validation-error",
                                                    attrs: {
                                                      disabled:
                                                        "isRestrictedRebateUser"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "country",
                                                            "page5"
                                                          )
                                                        )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "gds-input-field" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gds-input-field__label"
                                              },
                                              [_vm._v("Street Address")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.productSubmission.rebate
                                                      .rebateMailingAddress
                                                      .street1,
                                                  expression:
                                                    "productSubmission.rebate.rebateMailingAddress.street1"
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'"
                                                }
                                              ],
                                              key: "street address",
                                              staticClass:
                                                "eicproduct-input-field gds-input-field__input",
                                              attrs: {
                                                name: "street address",
                                                disabled:
                                                  _vm.isRestrictedRebateUser,
                                                type: "text",
                                                placeholder: "Type Here..."
                                              },
                                              domProps: {
                                                value:
                                                  _vm.productSubmission.rebate
                                                    .rebateMailingAddress
                                                    .street1
                                              },
                                              on: {
                                                input: [
                                                  function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress,
                                                      "street1",
                                                      $event.target.value
                                                    )
                                                  },
                                                  function($event) {
                                                    return _vm.$emit(
                                                      "touched",
                                                      true
                                                    )
                                                  }
                                                ]
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "gds-input-field" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gds-input-field__label"
                                              },
                                              [_vm._v("Suite Apt")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.productSubmission.rebate
                                                      .rebateMailingAddress
                                                      .street2,
                                                  expression:
                                                    "productSubmission.rebate.rebateMailingAddress.street2"
                                                }
                                              ],
                                              key: "street 2",
                                              staticClass:
                                                "eicproduct-input-field gds-input-field__input",
                                              attrs: {
                                                name: "street 2",
                                                disabled:
                                                  _vm.isRestrictedRebateUser,
                                                type: "text",
                                                placeholder: "Type Here..."
                                              },
                                              domProps: {
                                                value:
                                                  _vm.productSubmission.rebate
                                                    .rebateMailingAddress
                                                    .street2
                                              },
                                              on: {
                                                input: [
                                                  function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress,
                                                      "street2",
                                                      $event.target.value
                                                    )
                                                  },
                                                  function($event) {
                                                    return _vm.$emit(
                                                      "touched",
                                                      true
                                                    )
                                                  }
                                                ]
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row-l" }, [
                                          _c(
                                            "div",
                                            { staticClass: "flex-item-l" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "gds-input-field-flex"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [_vm._v("City")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.productSubmission
                                                            .rebate
                                                            .rebateMailingAddress
                                                            .city,
                                                        expression:
                                                          "productSubmission.rebate.rebateMailingAddress.city"
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression: "'required'"
                                                      }
                                                    ],
                                                    key: "city",
                                                    staticClass:
                                                      "gds-input-field__input",
                                                    attrs: {
                                                      name: "city",
                                                      disabled:
                                                        _vm.isRestrictedRebateUser,
                                                      type: "text",
                                                      placeholder:
                                                        "Type Here..."
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .rebateMailingAddress
                                                          .city
                                                    },
                                                    on: {
                                                      input: [
                                                        function($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm
                                                              .productSubmission
                                                              .rebate
                                                              .rebateMailingAddress,
                                                            "city",
                                                            $event.target.value
                                                          )
                                                        },
                                                        function($event) {
                                                          return _vm.$emit(
                                                            "touched",
                                                            true
                                                          )
                                                        }
                                                      ]
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "city",
                                                    "page6"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "city",
                                                                "page6"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-item-s gds-space-stack-m"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .rebateMailingAddress
                                                          .country === "USA",
                                                      expression:
                                                        "productSubmission.rebate.rebateMailingAddress.country === 'USA'"
                                                    }
                                                  ],
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [_vm._v("State")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .productSubmission
                                                              .rebate
                                                              .rebateMailingAddress
                                                              .state,
                                                          expression:
                                                            "productSubmission.rebate.rebateMailingAddress.state"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "eicproduct-input-field__select gds-input-field",
                                                      attrs: {
                                                        disabled:
                                                          _vm.isRestrictedRebateUser
                                                      },
                                                      on: {
                                                        change: [
                                                          function($event) {
                                                            var $$selectedVal = Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function(o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function(o) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                            _vm.$set(
                                                              _vm
                                                                .productSubmission
                                                                .rebate
                                                                .rebateMailingAddress,
                                                              "state",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                          function($event) {
                                                            return _vm.$emit(
                                                              "touched",
                                                              true
                                                            )
                                                          }
                                                        ]
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "",
                                                            disabled: "",
                                                            selected: ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Select State..."
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.stateList,
                                                        function(state) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: state,
                                                              domProps: {
                                                                value: state
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(state)
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: !_vm
                                                            .productSubmission
                                                            .rebate
                                                            .rebateMailingAddress
                                                            .state,
                                                          expression:
                                                            "!productSubmission.rebate.rebateMailingAddress.state"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "eicproduct-validation-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "state",
                                                            "page6"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .rebateMailingAddress
                                                          .country === "CAN",
                                                      expression:
                                                        "productSubmission.rebate.rebateMailingAddress.country === 'CAN'"
                                                    }
                                                  ],
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [_vm._v("Province")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .productSubmission
                                                              .rebate
                                                              .rebateMailingAddress
                                                              .state,
                                                          expression:
                                                            "productSubmission.rebate.rebateMailingAddress.state"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "eicproduct-input-field__select gds-input-field",
                                                      attrs: {
                                                        disabled:
                                                          _vm.isRestrictedRebateUser
                                                      },
                                                      on: {
                                                        change: [
                                                          function($event) {
                                                            var $$selectedVal = Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function(o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function(o) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                            _vm.$set(
                                                              _vm
                                                                .productSubmission
                                                                .rebate
                                                                .rebateMailingAddress,
                                                              "state",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                          function($event) {
                                                            return _vm.$emit(
                                                              "touched",
                                                              true
                                                            )
                                                          }
                                                        ]
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "",
                                                            disabled: "",
                                                            selected: ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Select Province..."
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.provinceList,
                                                        function(province) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: province,
                                                              domProps: {
                                                                value: province
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  province
                                                                ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: !_vm
                                                            .productSubmission
                                                            .rebate
                                                            .rebateMailingAddress
                                                            .state,
                                                          expression:
                                                            "!productSubmission.rebate.rebateMailingAddress.state"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "eicproduct-validation-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "province",
                                                            "page6"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div"),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-item-m" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [_vm._v("Zipcode")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.productSubmission
                                                            .rebate
                                                            .rebateMailingAddress
                                                            .zip,
                                                        expression:
                                                          "productSubmission.rebate.rebateMailingAddress.zip"
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value:
                                                          "required|numeric|length:5",
                                                        expression:
                                                          "'required|numeric|length:5'"
                                                      }
                                                    ],
                                                    key: "zipcode",
                                                    staticClass:
                                                      "gds-input-field__input eicproduct-mailing-zip",
                                                    attrs: {
                                                      name: "zipcode",
                                                      disabled:
                                                        _vm.isRestrictedRebateUser,
                                                      type: "text",
                                                      placeholder:
                                                        "Type Here...",
                                                      autocomplete:
                                                        "postal-code"
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .rebateMailingAddress
                                                          .zip
                                                    },
                                                    on: {
                                                      input: [
                                                        function($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm
                                                              .productSubmission
                                                              .rebate
                                                              .rebateMailingAddress,
                                                            "zip",
                                                            $event.target.value
                                                          )
                                                        },
                                                        function($event) {
                                                          return _vm.$emit(
                                                            "touched",
                                                            true
                                                          )
                                                        }
                                                      ]
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "zipcode",
                                                    "page6"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "zipcode",
                                                                "page6"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _vm._v(" "),
                                              !_vm.productSubmission.rebate
                                                .rebateMailingAddress.zip &&
                                              _vm.productSubmission.rebate
                                                .rebateMailingAddress
                                                .country === "USA"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "eicproduct-validation-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "zip",
                                                            "page6"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.productSubmission.rebate
                                                .rebateMailingAddress.zip &&
                                              _vm.productSubmission.rebate
                                                .rebateMailingAddress
                                                .country === "CAN"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "eicproduct-validation-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "canadianZip",
                                                            "page6"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          !_vm.isRestrictedRebateUser
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "eicproduct-title gds-space-stack-ml"
                                                },
                                                [
                                                  _vm._v(
                                                    "How should we contact you if we need additional information to process\n                    your rebate:"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.isRestrictedRebateUser
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "eicproduct-title gds-space-stack-ml"
                                                },
                                                [
                                                  _vm._v(
                                                    "What is the email address and phone number of the customer that received the rebate?"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "gds-space-stack-s"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  !_vm.isRestrictedRebateUser
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "gds-input-field__label"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Email Address"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.isRestrictedRebateUser
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "gds-input-field__label"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Customer Email Address"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.productSubmission
                                                            .rebate
                                                            .confirmationEmailAddress,
                                                        expression:
                                                          "productSubmission.rebate.confirmationEmailAddress"
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value:
                                                          "required|email|max:74",
                                                        expression:
                                                          "'required|email|max:74'"
                                                      }
                                                    ],
                                                    key: "email address",
                                                    ref: "email",
                                                    staticClass:
                                                      "eicproduct-input-field gds-input-field__input gds-space-stack-ml",
                                                    attrs: {
                                                      name: "email address",
                                                      type: "email",
                                                      placeholder:
                                                        "Enter email address",
                                                      autocomplete: "email"
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .confirmationEmailAddress
                                                    },
                                                    on: {
                                                      input: [
                                                        function($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm
                                                              .productSubmission
                                                              .rebate,
                                                            "confirmationEmailAddress",
                                                            $event.target.value
                                                          )
                                                        },
                                                        function($event) {
                                                          return _vm.$emit(
                                                            "touched",
                                                            true
                                                          )
                                                        }
                                                      ]
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "email address",
                                                    "page6"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "email address",
                                                                "page6"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.isRestrictedRebateUser
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-m"
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "gds-input-field"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "gds-input-field__label"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Confirm Your Email Address"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.confirmationEmailAddress,
                                                            expression:
                                                              "confirmationEmailAddress"
                                                          },
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value:
                                                              "required|email",
                                                            expression:
                                                              "'required|email'"
                                                          }
                                                        ],
                                                        key:
                                                          "confirmation email address",
                                                        ref: "email",
                                                        staticClass:
                                                          "eicproduct-input-field gds-input-field__input",
                                                        attrs: {
                                                          name:
                                                            "confirmation email address",
                                                          type: "email",
                                                          placeholder:
                                                            "Your email",
                                                          autocomplete: "email"
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.confirmationEmailAddress
                                                        },
                                                        on: {
                                                          blur: function(
                                                            $event
                                                          ) {
                                                            return _vm.addConfirmationEmailError()
                                                          },
                                                          input: [
                                                            function($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.confirmationEmailAddress =
                                                                $event.target.value
                                                            },
                                                            function($event) {
                                                              return _vm.$emit(
                                                                "touched",
                                                                true
                                                              )
                                                            }
                                                          ]
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.productSubmission
                                                        .rebate
                                                        .confirmationEmailAddress !==
                                                      _vm.confirmationEmailAddress
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "validation-error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.errors.first(
                                                                    "confirmation email address",
                                                                    "page6"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            { staticClass: "gds-input-field" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "gds-input-field__label"
                                                },
                                                [_vm._v("Phone Number")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-ml"
                                                },
                                                [
                                                  _c("the-mask", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value:
                                                          "required|min:10",
                                                        expression:
                                                          "'required|min:10'"
                                                      }
                                                    ],
                                                    key: "phone number",
                                                    staticClass:
                                                      "eicproduct-input-field gds-input-field__input",
                                                    attrs: {
                                                      mask: "+1 (###) ###-####",
                                                      "data-vv-validate-on":
                                                        "input",
                                                      name: "phone number",
                                                      type: "tel",
                                                      placeholder:
                                                        "Your phone number"
                                                    },
                                                    nativeOn: {
                                                      input: function($event) {
                                                        _vm.touched = true
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .contactPhoneNumber,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.productSubmission
                                                            .rebate,
                                                          "contactPhoneNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "productSubmission.rebate.contactPhoneNumber"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "phone number",
                                                    "page6"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "phone number",
                                                                "page6"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.isRestrictedRebateUser
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-m"
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "gds-checkbox gds-checkbox-thin"
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.customerConfirmation,
                                                            expression:
                                                              "customerConfirmation"
                                                          },
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value: "required",
                                                            expression:
                                                              "'required'"
                                                          }
                                                        ],
                                                        key:
                                                          "customer confirmation",
                                                        attrs: {
                                                          type: "checkbox",
                                                          name:
                                                            "customer confirmation"
                                                        },
                                                        domProps: {
                                                          checked: Array.isArray(
                                                            _vm.customerConfirmation
                                                          )
                                                            ? _vm._i(
                                                                _vm.customerConfirmation,
                                                                null
                                                              ) > -1
                                                            : _vm.customerConfirmation
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.customerConfirmation,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  (_vm.customerConfirmation = $$a.concat(
                                                                    [$$v]
                                                                  ))
                                                              } else {
                                                                $$i > -1 &&
                                                                  (_vm.customerConfirmation = $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    ))
                                                              }
                                                            } else {
                                                              _vm.customerConfirmation = $$c
                                                            }
                                                          }
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", {
                                                        staticClass:
                                                          "gds-checkbox__faux"
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "gds-font-size-ml regulatory-text1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          Please confirm the information above is accurate for the customer that you are submitting this rebate for.\n                        "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.errors.first(
                                                        "customer confirmation",
                                                        "page6"
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "eicproduct-validation-error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.errors.first(
                                                                    "customer confirmation",
                                                                    "page6"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.isAlreadyEbillEnrolled &&
                                          !_vm.isRestrictedRebateUser
                                            ? _c("div", [
                                                _vm._m(15),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-body-small gds-space-stack-l slat3-body"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      You can reduce your carbon footprint even more,\n                      by switching to paperless billing! Paperless monthly energy\n                      statements cut carbon and clutter while saving trees.\n                    "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-space-stack-ml"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "gds-checkbox gds-checkbox-thin"
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .productSubmission
                                                                  .programEnrollment
                                                                  .ebill
                                                                  .ebillEnroll,
                                                              expression:
                                                                "productSubmission.programEnrollment.ebill.ebillEnroll"
                                                            }
                                                          ],
                                                          attrs: {
                                                            type: "checkbox",
                                                            name: ""
                                                          },
                                                          domProps: {
                                                            checked: Array.isArray(
                                                              _vm
                                                                .productSubmission
                                                                .programEnrollment
                                                                .ebill
                                                                .ebillEnroll
                                                            )
                                                              ? _vm._i(
                                                                  _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .ebill
                                                                    .ebillEnroll,
                                                                  null
                                                                ) > -1
                                                              : _vm
                                                                  .productSubmission
                                                                  .programEnrollment
                                                                  .ebill
                                                                  .ebillEnroll
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .ebill
                                                                    .ebillEnroll,
                                                                $$el =
                                                                  $event.target,
                                                                $$c = $$el.checked
                                                                  ? true
                                                                  : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .programEnrollment
                                                                        .ebill,
                                                                      "ebillEnroll",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .programEnrollment
                                                                        .ebill,
                                                                      "ebillEnroll",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .ebill,
                                                                  "ebillEnroll",
                                                                  $$c
                                                                )
                                                              }
                                                            }
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", {
                                                          staticClass:
                                                            "gds-checkbox__faux"
                                                        }),
                                                        _vm._v(" "),
                                                        _vm._m(16)
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.productSubmission
                                                  .programEnrollment.ebill
                                                  .ebillEnroll
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "gds-space-stack-ml"
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "gds-input-field"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "gds-input-field__label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Account email address"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .programEnrollment
                                                                      .ebill
                                                                      .ebillEmailAddress,
                                                                  expression:
                                                                    "productSubmission.programEnrollment.ebill.ebillEmailAddress"
                                                                },
                                                                {
                                                                  name:
                                                                    "validate",
                                                                  rawName:
                                                                    "v-validate",
                                                                  value:
                                                                    "required|email",
                                                                  expression:
                                                                    "'required|email'"
                                                                }
                                                              ],
                                                              key:
                                                                "account email address",
                                                              ref: "email",
                                                              staticClass:
                                                                "eicproduct-input-field gds-input-field__input",
                                                              attrs: {
                                                                name:
                                                                  "account email address",
                                                                type: "email",
                                                                placeholder:
                                                                  "Your email",
                                                                autocomplete:
                                                                  "email"
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .ebill
                                                                    .ebillEmailAddress
                                                              },
                                                              on: {
                                                                input: [
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .programEnrollment
                                                                        .ebill,
                                                                      "ebillEmailAddress",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$emit(
                                                                      "touched",
                                                                      true
                                                                    )
                                                                  }
                                                                ]
                                                              }
                                                            }),
                                                            _vm._v(" "),
                                                            _vm.errors.first(
                                                              "account email address",
                                                              "page6"
                                                            )
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "validation-error"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.errors.first(
                                                                          "account email address",
                                                                          "page6"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("br")
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct__method-next-btn gds-button gds-compact",
                                        attrs: {
                                          disabled:
                                            _vm.isRestrictedRebateUser &&
                                            !_vm.customerConfirmation
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.finishPage("page6", 7)
                                          }
                                        }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-7" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 7
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("7")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 7
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(17)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage < 7
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 7
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-l" },
                                  [
                                    !_vm.isRestrictedRebateUser
                                      ? _c(
                                          "div",
                                          {
                                            ref: "focuser7",
                                            staticClass:
                                              "gds-display-00 gds-space-stack-s"
                                          },
                                          [
                                            _vm._v(
                                              "Your rebate will be mailed to:"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isRestrictedRebateUser
                                      ? _c(
                                          "div",
                                          {
                                            ref: "focuser7",
                                            staticClass:
                                              "gds-display-00 gds-space-stack-s"
                                          },
                                          [
                                            _vm._v(
                                              "Total rebate received by customer:"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-small gds-space-stack-s"
                                      },
                                      [
                                        _vm._v(
                                          "Amount: $" + _vm._s(_vm.totalSavings)
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-small gds-space-stack-s"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("titlecase")(
                                              _vm.productSubmission.rebate
                                                .rebatePayeeName
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-small gds-space-stack-s"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.productSubmission.rebate
                                              .rebateMailingAddress.street1
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-small gds-space-stack-ml"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.productSubmission.rebate
                                              .rebateMailingAddress.city
                                          ) +
                                            " " +
                                            _vm._s(
                                              _vm.productSubmission.rebate
                                                .rebateMailingAddress.state
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.productSubmission.rebate
                                                .rebateMailingAddress.zip
                                            )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !_vm.isRestrictedRebateUser
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-display-00 gds-space-stack-s"
                                          },
                                          [_vm._v("Your Vehicle Information:")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isRestrictedRebateUser
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-display-00 gds-space-stack-s"
                                          },
                                          [
                                            _vm._v(
                                              "The customer's Vehicle Information:"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-small gds-space-stack-l"
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            "Make/Model: " +
                                              _vm._s(
                                                _vm.productSubmission
                                                  .vehicles[0].brandModel
                                              )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            "Engine type: " +
                                              _vm._s(
                                                _vm.productSubmission
                                                  .vehicles[0].engineType
                                              )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            "VIN: " +
                                              _vm._s(
                                                _vm.productSubmission.vehicles[0].vinNumber.toUpperCase()
                                              )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.incomeQualifiedRebate > 0 ||
                                    _vm.productSubmission.programEnrollment
                                      .ebill.enrollInHomeChargerProgram
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-display-00 gds-space-stack-ml"
                                          },
                                          [_vm._v("Savings & Offers:")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "ol",
                                      { staticClass: "gds-space-stack-ml" },
                                      [
                                        _vm.incomeQualifiedRebate > 0
                                          ? _c(
                                              "li",
                                              {
                                                staticClass: "gds-space-stack-s"
                                              },
                                              [
                                                !_vm.isRestrictedRebateUser
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "You qualify for an income based rebate which will be included in your rebate check."
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.isRestrictedRebateUser
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "The customer qualifies for an income based rebate which will be included in their rebate check."
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.isRestrictedRebateUser &&
                                        _vm.productSubmission.programEnrollment
                                          .inHomeChargerProgram
                                          .enrollInHomeChargerProgram
                                          ? _c(
                                              "li",
                                              {
                                                staticClass: "gds-space-stack-s"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Great News! You've decided that you’d like a Free Level 2 Charger. We’ll send you an\n                  email in the next couple days with an agreement and additional\n                  information about the program.\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isRestrictedRebateUser &&
                                        _vm.productSubmission.programEnrollment
                                          .inHomeChargerProgram
                                          .enrollInHomeChargerProgram
                                          ? _c(
                                              "li",
                                              {
                                                staticClass: "gds-space-stack-s"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  We’ll follow-up with the customer based on their interest in the home charger program\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !_vm.isRestrictedRebateUser
                                      ? _c(
                                          "div",
                                          { staticClass: "gds-space-stack-m" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "gds-checkbox gds-checkbox-thin"
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.regulatoryDataSharing,
                                                      expression:
                                                        "regulatoryDataSharing"
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'"
                                                    }
                                                  ],
                                                  key:
                                                    "regulatory data sharing",
                                                  attrs: {
                                                    type: "checkbox",
                                                    name:
                                                      "regulatory data sharing"
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.regulatoryDataSharing
                                                    )
                                                      ? _vm._i(
                                                          _vm.regulatoryDataSharing,
                                                          null
                                                        ) > -1
                                                      : _vm.regulatoryDataSharing
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      var $$a =
                                                          _vm.regulatoryDataSharing,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.regulatoryDataSharing = $$a.concat(
                                                              [$$v]
                                                            ))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.regulatoryDataSharing = $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                        }
                                                      } else {
                                                        _vm.regulatoryDataSharing = $$c
                                                      }
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass:
                                                    "gds-checkbox__faux"
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gds-font-size-ml regulatory-text1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.REGULATORY_REBATE_SHARING_TEXT1
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "gds-font-size-ml regulatory-text2"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.REGULATORY_REBATE_SHARING_TEXT2
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.errors.first(
                                                  "regulatory data sharing",
                                                  "page7"
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproduct-validation-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.errors.first(
                                                              "regulatory data sharing",
                                                              "page7"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.pendingSubmit
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "eicproduct__method-next-btn gds-button gds-compact",
                                            attrs: {
                                              type: "submit",
                                              disabled:
                                                _vm.pendingSubmit ||
                                                (!_vm.regulatoryDataSharing &&
                                                  !_vm.isRestrictedRebateUser)
                                            },
                                            on: { click: _vm.handleSubmit }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "gds-button__text",
                                                attrs: {
                                                  disabled:
                                                    _vm.pendingSave ||
                                                    !_vm.touched ||
                                                    (!_vm.regulatoryDataSharing &&
                                                      !_vm.isRestrictedRebateUser) ||
                                                    _vm.errors.any()
                                                }
                                              },
                                              [_vm._v("Submit Rebate")]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.pendingSubmit
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "gds-button [modifier class] gds-loading"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "gds-button__text"
                                              },
                                              [_vm._v("Submit Rebate")]
                                            ),
                                            _vm._v(" "),
                                            _c("figure", {
                                              staticClass: "gds-button__icon"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Your Customer Information")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Name on Account")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Service Address")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Account Number")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Name on Account")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Service Address")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Account Number")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Your Vehicle Details")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "gds-radio__label" }, [
      _c("b", [_vm._v("All-Electric")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "gds-radio__label" }, [
      _c("b", [_vm._v("Plug-in Hybrid")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "gds-radio__label" }, [
      _c("b", [_vm._v("New Vehicle")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "gds-radio__label" }, [
      _c("b", [_vm._v("Used Vehicle")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Savings")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("In-Home Level 2 EV Charger")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Contact & Mailing Address")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-body-small gds-space-stack-l" }, [
      _c("span", { staticClass: "gds-display-1 gds-space-stack-s" }, [
        _vm._v("Would you like to go paperless?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "gds-font-size-ml" }, [
      _c("b", [_vm._v("Yes!")]),
      _vm._v(
        " - Enroll me in paperless billing so I can reduce my carbon footprint even more.\n                        "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Review & Submit")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }