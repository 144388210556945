<template>
  <div class="payment-history">
    <div class="payment-history--header gds-flex-container gds-flex-container--space-between">
      <div class="payment-history--item-date gds-font-size-s">Date</div>
      <div class="payment-history--item-icon"></div>
      <div class="payment-history--view-bill-wrap"></div>
      <div class="payment-history--item-amount gds-font-size-s">New Charges</div>
    </div>
    <div v-if="loadState === undefined" class="table-loading" />
    <div v-if="loadState === 'error'" class="table-error">Unable to load your recent transactions and bills. Please try again later.</div>
    <div v-if="loadState === 'empty'" class="table-error">No bills or payments found within the last two years.</div>
    <div v-if="loadState === 'complete'">
      <div v-for="transaction of visibleTransactions" :key="transaction.transactionId" class="payment-history--item">
        <div class="payment-history--item-icon">
          <svg class="gds-icon">
            <use v-if="transaction.type === 'Bill Segment'" xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-document" />
            <use v-if="transaction.type === 'Pay Segment'" xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-payment" />
          </svg>
          <span class="payment-history--item-type gds-mobile-only">{{transaction.type | transactionType}}</span>

        </div>
        <div class="payment-history--item-date gds-font-size-m gds-flex-container gds-flex-container--left">
          <div class="gds-space-inline-s">{{transaction.date | dateFull}} <span v-if="TransactionIsNew(transaction)" class="gds-badge new-item">New</span>
</div>

        </div>
        <div class="payment-history--item-type gds-font-size-m gds-desktop-only">{{transaction.type | transactionType}}</div>
        <div class="payment-history--view-bill-wrap">
          <a
            v-if="transaction.type === 'Bill Segment' && transaction.url"
            :href="transaction.url"
            target="_blank"
            class="payment-history--view-bill-button gds-button gds-button-text gds-compact gds-button-underline"
          >View Bill</a>
          <a
            v-if="transaction.type === 'Bill Segment' && testDriveUsage"
            :href="UsageLink(transaction)"
            class="payment-history--view-bill-button gds-button gds-button-text gds-compact gds-button-underline"
          >View Usage</a>
        </div>
        <div class="payment-history--item-amount gds-font-size-xl">{{transaction.payoffAmount | currency}}</div>
      </div>
      <div v-if="visibleCount < transactions.length" class="payment-history--load-more-wrap gds-flex-container">
        <button @click="visibleCount += 8" type="button" class="gds-button red gds-secondary">
          <span class="gds-button__text">Load More</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { addDays, addYears, differenceInCalendarDays } from 'date-fns';
import GMPAPI from '../../../services/gmpapi';
import { ToServerDateTruncate, DumpError } from '../../../utilities';

export default {
  name: "WidgetPaymentHistory",
  components: {
  },
  data() {
    return {
      transactions: undefined,
      visibleCount: 8,
      loadState: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    visibleTransactions() {
      if (!this.transactions) return undefined;
      return this.transactions.slice(0, this.visibleCount);
    },
    testDriveUsage() {
      // GRID-084 - for rollback, swap the lines below
      //  return this.$store.state.user.testDriveUsage;
      return true;
    },
  },
  async mounted() {
    await this.RefreshHistory();
  },
  methods: {
    async RefreshHistory() {
      this.transactions = undefined;
      this.loadState = undefined;
      if (!this.currentAccount) {
        this.loadState = "error";
        return;
      }
      try {
        this.visibleCount = 8;
        const start = addYears(new Date(), -2);
        const end = addDays(new Date(), 1);
        const allTransactions = await GMPAPI.GetAccountTransactions(this.currentAccount.accountNumber, ToServerDateTruncate(start), ToServerDateTruncate(end));
        const filteredTransactions = allTransactions.filter(item => item.type === "Bill Segment" || item.type === "Pay Segment");
        if (filteredTransactions.length === 0) {
          this.loadState = "empty";
        } else {
          this.transactions = filteredTransactions;
          this.loadState = "complete";
        }
      } catch (err) {
        DumpError("Payment history card refresh error", err);
        this.loadState = "error";
      }
    },
    /** Returns true if transaction is a bill, and 30 days old or less */
    TransactionIsNew(transaction) {
      return transaction.type === "Bill Segment" && differenceInCalendarDays(new Date(), transaction.date) <= 30;
    },

    UsageLink(transaction) {
      if (transaction.type !== "Bill Segment") return undefined;
      const date = addDays(transaction.date, -2);
      return `/account/usage/detail/table/?startDate=${date.toUsEasternISOString().split('T')[0]}&periodType=billing-period`
    },
  },
  watch: {
    async currentAccount() {
      await this.RefreshHistory();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>